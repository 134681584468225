/* eslint-disable camelcase */
import API from './api';
import { services } from '~/config/constants';
import { objectToParams } from '~/utils/helpers';

const { coreService } = services;

export const getMemberRequests = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/requests`
        }
    );
};

export const approveMember = async (id: string, groupId: string, memberId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/members/${memberId}`,
            method: 'POST'
        }
    );
};

export const rejectMember = async (id: string, groupId: string, memberId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/members/${memberId}/reject`,
            method: 'POST'
        }
    );
};

export const removeMember = async (id: string, groupId: string, memberId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/members/${memberId}`,
            method: 'DELETE'
        }
    );
};

export const getIsGroupAdmin = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}`
        }
    );
};

export const getMembers = async (id: string, groupId: string, params: {
    industry?: string,
    service_start_date?: number,
    service_end_date?: number,
    work_experience?: string,
    start_work_date?: number,
    end_work_date?: number,
    groups?: string,
    type?: string,
}) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/members?${objectToParams(params)}`
        }
    );
};

export const approveRemoveGroupAdmin = async (id: string, groupId: string, memberId: string, isRemove?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/group_admin/${memberId}`,
            method: isRemove ? 'DELETE' : 'POST'
        }
    );
};

export const getMembersByIds = async (id: string, members: string[]) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/members`,
            method: 'POST',
            data: {
                members
            }
        }
    );
};

export const getMembersByGroupIdAndIds = async (id: string, groupId: string, members: string[]) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/chat/members`,
            method: 'POST',
            data: {
                members
            }
        }
    );
};

export const removeBlackListedMember = async (id: string, groupId: string, member_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/manage/members/black-listed/${member_id}`,
            method: 'DELETE'
        }
    );
};

export const onGetBlackListedMember = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/can-join`
        }
    );
};

export const approveRemoveGroupEventAdmin = async (id: string, groupId: string, memberId: string, isRemove?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/manage/event-admin/${memberId}`,
            method: isRemove ? 'DELETE' : 'POST'
        }
    );
};

export const approveRemoveGroupVoiceNewsCreator = async (id: string, groupId: string, memberId: string, isRemove?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/manage/voice-news-creator/${memberId}`,
            method: isRemove ? 'DELETE' : 'POST'
        }
    );
};
