import React from 'react';
import { ButtonVariant } from '~/components/base/Button/Button';
import ModalConfirm from '~/components/modules/ModalConfirm';
import { useAppDispatch } from '~/redux/hooks';
import { clearModalContent, setModalContent } from '~/redux/modules/modalEvent';

type ButtonType = {
    title: string;
    onClick: () => void;
    variant?: ButtonVariant
};

type Props = {
    className?: string;
    title: string;
    save: ButtonType;
    cancel: ButtonType;
    description?: string;
};

const useModalConfirm = ({
    className,
    title,
    save,
    cancel,
    description
}: Props) => {
    const dispatch = useAppDispatch();

    const onCloseModal = () => {
        dispatch(clearModalContent());
    };

    const onHandleLeft = () => {
        onCloseModal();
        cancel.onClick();
    };

    const onHandleRight = () => {
        onCloseModal();
        save.onClick();
    };

    const onShowModal = () => {
        dispatch(
            setModalContent({
                title,
                modalContent: (
                    <ModalConfirm
                        className={className}
                        leftButtonTitle={cancel.title}
                        rightButtonTitle={save.title}
                        onLeft={onHandleLeft}
                        onRight={onHandleRight}
                        description={description}
                        leftButtonVariant={cancel.variant}
                        rightButtonVariant={save.variant}/>
                )
            })
        );
    };

    return onShowModal;
};

export default useModalConfirm;
