/* eslint-disable space-before-function-paren */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setIsShowedOpenAppPopup } from '~/redux/modules/authentication';
import { isMobileOnly } from 'react-device-detect';

const useShowOpenAppPopup = () => {
    const { isShowedOpenAppPopup } = useAppSelector(({ authentication }) => authentication);
    const dispatch = useAppDispatch();
    const searchParams = new URLSearchParams(window.location.search);
    const isHidden = searchParams.get('pop-up') === 'false';

    const handleBeforeUnload = () => {
        dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: false }));
    };

    useEffect(() => {
        if (isMobileOnly) {
            dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: false }));
        }
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();

            e.returnValue = '';

            handleBeforeUnload();
        });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return isMobileOnly && !isHidden ? isShowedOpenAppPopup : true;
};

export default useShowOpenAppPopup;
